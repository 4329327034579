
















































































































































































































































































.card-section {
  margin-bottom: 30px;

  &-header {
    font-weight: bold;
    margin-bottom: 5px;

    &-name {
      display: inline-block;
      min-width: 100px;
    }
  }
}

