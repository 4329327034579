


















































































































































































































































































































.card-edit-dialog-stage:not(:last-child){
  margin-bottom: 20px;
}

.card-edit-dialog-stage-content{
  padding-left:20px;
  padding-top: 20px;

  &-dates{
    margin: 10px 0;

    &-datepicker{
      width:140px;
    }
  }
}

.sections-group {
  label.el-checkbox {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 5px;
    display: block;
  }
}


