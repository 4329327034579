



























































































































































































































































@import "./src/assets/css/project-variables";

.click-to-edit-div {
  min-height: 22px;
}

.click-to-edit-div.click-to-edit-enabled:hover {
  cursor: pointer;
}

.click-to-edit-enabled {
  //background-color: #f0f7ff;
  border: $--editable-border;
  border-radius: $--border-radius-base;
  padding: 1px 5px;
}

.click-to-edit-div .placeholder {
  color: #afafaf;
  font-style: italic;
}


